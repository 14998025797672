.mainVideoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  column-gap: 0px;
  row-gap: 0px;
  width: 100%;
}
.video-container {
  padding: 0px;
}
.fullScreen {
  transform: scale(-1, 1);
}
.fullScreen .muteIcon {
  left: 0px !important;
}
.fullScreen .localParticipantSwitch {
  object-fit: unset;
}
.fullScreen .videoNotAvailable {
  height: 90vh !important;
}
.leaveCall {
  border-radius: 12px;
  background-color: red !important;
}
.iconContainer {
  background-color: black;
}
.video-icon {
  height: 50px;
}
/* large screen */
@media only screen and (min-width: 1700px) and (max-width: 2000px) {
  .CoworkersIcon {
    left: 1085px;
  }
  .FocusAbstract {
    left: 960px;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 1699px) {
  .videoMain {
    margin-right: 2%;
    margin-left: 2%;
  }
  .CoworkersIcon {
    left: 968px;
  }
  .FocusAbstract {
    left: 828px;
  }
}
@media only screen and (min-width: 1209px) and (max-width: 1440px) {
  .CoworkersIcon {
    left: 882px;
    top: 386px;
  }
  .FocusAbstract {
    left: 786px;
  }
  .classSectionInner {
    width: 62%;
    justify-content: space-between;
  }
  .roomBookingCard {
    width: 48%;
    margin: 60px 32px 20px 0;
  }
  .todayBookingContainer {
    width: 38%;
  }
  .myBooking {
    width: 58%;
  }
  .cardHeading {
    font-size: 32px;
    margin-bottom: 14px !important;
  }
  .ClassJoinSection {
    width: 100%;
  }
  .MyBookings {
    width: auto !important;
  }
}
@media only screen and (min-width: 1049px) and (max-width: 1210px) {
  .videoOuter {
    margin: auto 0px;
  }
  .LinkImages {
    width: 290px !important;
  }
  .link {
    font-size: 30px;
  }
  .CoworkersIcon {
    left: 748px;
  }
  .FocusAbstract {
    left: 626px;
  }
  .bgImage {
    height: 409px;
  }
  .stopSignImg {
    width: 361px;
    height: 348px;
    margin-left: 0px;
  }
  .heading {
    font-size: 54px;
  }
  .heading2 {
    font-size: 20px;
    line-height: 33px;
  }
  .logo {
    height: 33px;
    width: 23px;
  }
  .pageContent {
    padding-top: 37px;
  }
  .roomBookingCard {
    width: 48%;
  }
  .todayBookingContainer {
    width: 38%;
  }
  .cardHeading {
    font-size: 32px;
  }
  .ClassJoinSection {
    width: 100%;
  }
}
/*Medium screen  */
@media only screen and (min-width: 930px) and (max-width: 1048px) {
  .CoworkersIcon {
    left: 821px;
    top: 384px;
  }
  .FocusAbstract {
    left: 744px;
  }
  .video-container {
    width: 50%;
  }
  .videoMain {
    height: 65vh;
  }
}
@media only screen and (min-width: 930px) and (max-width: 2000px) {
  .videoMain .video {
    object-fit: cover;
  }
  .videoMain {
    position: relative;
    border-radius: 8px;
    margin-right: 2%;
    margin-left: 2%;
    object-fit: cover;
    height: 65vh;
  }
  .videoNotAvailable {
    height: 65vh;
  }
  .mainVideoContainer {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 4px;
    height: 90vh;
  }
  .videoOuter.mainVideoContainer .videoMain {
    height: 42vh;
    margin-right: 1%;
    margin-left: 1%;
  }

  .videoOuter.mainVideoContainer .videoNotAvailable {
    height: 42vh;
  }
  .videoOuter.mainVideoContainer .video-container {
    height: 42vh;
  }
  .fullScreen {
    margin-top: 1%;
    height: 95vh !important;
  }
  .fullScreen .video {
    height: 90vh !important;
  }
  .videoOuter .mainVideoContainer {
    width: 82%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 929px) {
  .videoOuter {
    margin: 1% 0px;
  }
  .videoNotAvailable {
    height: 42vh;
  }
  .mainVideoContainer {
    height: 90vh;
    width: 96% !important;
  }
  .videoMain {
    margin-bottom: 4%;
    margin-right: 2%;
    margin-left: 3%;
  }
  .videoMain {
    position: relative;
    height: 42vh;
    border-radius: 8px;
  }
  .videoMain video {
    border-radius: 8px;
    object-fit: cover;
    height: 42vh;
  }
  .video-container {
    padding-top: 12px;
  }
  .fullScreen {
    margin-top: 1% !important;
    height: 86vh !important;
  }
}
@media only screen and (min-width: 870px) and (max-width: 929px) {
  .videoOuter {
    margin: 2% 0px;
    flex-wrap: wrap;
  }
  .mainVideoContainer {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .CoworkersIcon {
    left: 724px !important;
    top: 402px;
  }
  .FocusAbstract {
    left: 621px !important;
  }
}
@media only screen and (min-width: 782px) and (max-width: 869px) {
  .videoOuter {
    margin: 2% 0px;
    flex-wrap: wrap;
  }
  .mainVideoContainer {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .CoworkersIcon {
    left: 610px !important;
    width: 170px;
    height: 167px;
    top: 391px;
  }
  .FocusAbstract {
    left: 499px !important;
    width: 173px;
    height: 168px;
    top: 489px;
  }
}
@media only screen and (min-width: 780px) and (max-width: 1048px) {
  .LinkImages {
    width: 230px !important;
  }
  .stopSignImg {
    width: 303px;
    height: 289px;
    margin-left: 0px;
  }
  .bgImage {
    height: 405px;
  }
  .link {
    font-size: 22px;
  }
  .heading {
    font-size: 54px;
  }
  .heading2 {
    font-size: 20px;
  }
  .roomBookingCard {
    width: 470px;
  }
  .todayBookingContainer {
    width: 400px;
  }
  .cardHeading {
    font-size: 32px;
  }
  .ClassJoinSection {
    width: 440px;
  }
}
@media only screen and (min-width: 590px) and (max-width: 980px) {
  .roomBookingCard {
    width: 460px;
  }
  .todayBookingContainer {
    width: 460px;
  }
  .classSectionInner {
    width: 62%;
  }
  .cardHeading {
    font-size: 28px;
  }
  .ClassJoinSection {
    width: 420px;
  }
}
@media only screen and (min-width: 541px) and (max-width: 779px) {
  .logo {
    height: 35px;
    width: 25px;
  }
  .securityCard {
    margin: 20px;
    padding: 5% 40px;
  }
  .stopSignImg {
    width: 200px;
    height: 198px;
    margin-left: 0px;
  }
  .MyProfileCard {
    width: 499px !important;
  }
  .main {
    margin: 0px 10px;
  }
  .heading {
    font-size: 50px;
  }
  .heading2 {
    font-size: 20px;
    line-height: 34px;
  }
  .button {
    margin-top: 17px;
    padding: 8px 18px;
    font-size: 14px;
    line-height: 29px;
  }
  .link {
    font-size: 28px;
  }
  .linksDiv {
    display: contents;
  }
  .LinkImages {
    width: 299px;
  }
  .sideImages {
    margin-top: 22px;
  }
  .middleImg {
    margin-top: 5px;
  }
  .roomBookingCard {
    width: 500px;
  }
  .todayBookingContainer {
    width: 500px;
  }
  .cardHeading {
    font-size: 28px;
  }
  .ClassJoinSection {
    width: 440px;
  }
}
/* Mobile screen */
@media only screen and (min-width: 645px) and (max-width: 781px) {
  .CoworkersIcon {
    left: 599px !important;
    width: 133px;
    height: 129px;
    top: 415px;
  }
  .msgCard {
    width: 399px !important;
    height: 428px !important;
  }
  .contactUsContainer {
    width: 74% !important;
  }
  .followUsContainer {
    width: 26% !important;
  }
  .inputMainContainer .contactUsText,
  .inputContainer {
    width: 60%;
    margin-top: 2%;
    margin-inline-start: inherit;
  }
  .inputMainContainer .contactUsText {
    width: auto;
  }
  .FocusAbstract {
    left: 521px !important;
    width: 133px;
    height: 139px;
    top: 494px;
  }
}
@media only screen and (min-width: 539px) and (max-width: 644px) {
  .CoworkersIcon {
    margin-left: 47px !important;
    width: 133px;
    height: 129px;
    margin-top: -8px;
  }
  .msgCard {
    width: 389px !important;
    height: 428px !important;
  }
  .FocusAbstract {
    margin-top: 15px !important;
    margin-left: 167px !important;
    width: 133px;
    height: 129px;
    margin-top: 104px;
    position: sticky;
  }
  .contactUsContainer {
    width: 74% !important;
  }
  .followUsContainer {
    width: 26% !important;
  }
  .inputMainContainer .contactUsText,
  .inputContainer {
    width: 60%;
    margin-top: 2%;
    margin-inline-start: inherit;
  }
  .inputMainContainer .contactUsText {
    width: auto;
  }
}
@media only screen and (min-width: 380px) and (max-width: 435px) {
  .main {
    align-items: center;
    display: grid;
    justify-content: center;
  }
  .securityCard {
    padding: 5% 40px;
    width: 335px;
    height: 490px !important;
    margin: auto;
  }
  .stopSignImg {
    width: 290px;
    margin: auto;
    height: 290px;
  }
  .logo {
    height: 35px;
    width: 25px;
  }
  .heading {
    font-size: 33px;
    line-height: 65px;
  }
  .options {
    width: 95px !important;
  }
  .optionsPlaceholder {
    width: 95px;
    font-size: 15px;
  }
  .heading2 {
    font-size: 13px;
    line-height: 25px;
  }
  .MyProfileCard {
    width: 338px;
  }
  .button {
    margin-top: 17px;
    padding: 8px 18px;
    font-size: 14px;
    line-height: 29px;
  }
  .linksDiv {
    display: contents;
  }
  .link {
    font-size: 24px;
  }
  .LinkImages {
    width: 200px;
  }
  .sideImages {
    margin-top: 22px;
    text-align: center;
  }
  .middleImg {
    margin-top: 5px;
    text-align: center;
  }
  .roomBookingCard {
    width: 90%;
  }
  .todayBookingContainer {
    width: 90%;
  }
  .cardHeading {
    font-size: 28px;
  }
  .ClassJoinSection {
    width: 295px;
  }
  .BookingCards_Outer {
    display: contents;
  }
  .Timing {
    margin: 0px 20px 0px 4px !important;
    font-size: 14px;
  }
  .bookButton {
    font-size: 16px;
    width: 72px;
    padding: 0px;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }
  .cancelButton {
    font-size: 16px;
  }
}
@media only screen and (min-width: 436px) and (max-width: 540px) {
  .logo {
    height: 35px;
    width: 25px;
  }
  .FocusAbstract {
    position: inherit;
    margin-top: -12px;
  }
  .main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .contactUsContainer {
    width: 70% !important;
  }
  .followUsContainer {
    width: 30% !important;
  }
  .inputMainContainer .contactUsText,
  .inputContainer {
    width: 60%;
    margin-top: 2%;
    margin-inline-start: inherit;
  }
  .inputMainContainer .contactUsText {
    width: auto;
  }
  .securityCard {
    width: 448px;
    height: 490px;
  }
  .stopSignImg {
    width: 280px;
    height: 285px;
  }
  .MyProfileCard {
    margin-left: 28px;
    width: auto !important;
  }
  .classSectionInner {
    width: 60%;
  }
  .options {
    padding: 10px 0px;
  }
  .heading {
    font-size: 40px;
    line-height: 70px;
  }
  .heading2 {
    font-size: 14px;
    line-height: 29px;
  }
  .button {
    margin-top: 17px;
    padding: 8px 18px;
    font-size: 14px;
    line-height: 29px;
  }
  .linksDiv {
    display: contents;
  }
  .link {
    font-size: 28px;
  }
  .LinkImages {
    width: 240px;
  }
  .sideImages {
    margin-top: 22px;
    text-align: center;
  }
  .middleImg {
    margin-top: 5px;
    text-align: center;
  }
  .roomBookingCard {
    width: 500px;
  }
  .todayBookingContainer {
    width: 500px;
  }
  .cardHeading {
    font-size: 28px;
  }
  .ClassJoinSection {
    width: auto;
  }
  .BookingCards_Outer {
    display: contents;
  }
}
@media (max-width: 600px) {
  .videoMain video {
    height: 46vh !important;
     object-fit: cover !important;
  }

  .video-container {
    flex-basis: 50% !important;
    height: 46vh !important;
  }
  .leaveCall {
    border-radius: 6px !important;
  }
  .leaveCall img {
    margin-top: 3px;
  }
  .videoOuter .mainVideoContainer {
    width: 100% !important;
  }
  .iconContainer {
    height: 32px !important;
    width: 32px !important;
  }
  .iconContainer .leaveCall {
    border-radius: 5px !important;
  }
  .timeContainer {
    display: none;
  }
  .buttonContainer {
    bottom: 10px;
  }
  .fullScreen {
    flex-basis: 100%;
  }
  .videoOuter.startVideo {
    flex-wrap: wrap;
    height: 84vh;
    margin: auto 0px;
  }
  .videoOuter.centeredVideo {
    justify-content: start;
    align-items: start;
    flex-wrap: wrap;
    height: 84vh;
  }
  .leaveCall {
    left: 70%;
    bottom: -20%;
  }
  .videoLinkCard {
    right: 5%;
    left: 5%;
  }
  .video .avControl {
    left: 50%;
  }
  .audio .avControl {
    left: 30%;
  }
  .mainVideoContainer {
    height: auto;
  }
  .videoMain video {
    height: 42vh;
    width: 198px;
  }
  .mainVideoContainer {
    width: 100% !important;
    height: auto;
  }
}
@media (max-width: 480px) {
  .mainVideoContainer .buttonContainer {
    bottom: 10px;
  }
  .videoOuter {
    flex-wrap: wrap;
  }
  .classSection {
    display: grid;
    grid-auto-flow: column;
  }
  .classSectionInner {
    width: 100%;
  }
  .bookButton {
    height: 100%;
  }
  .sideImages {
    margin-top: 0;
    margin-bottom: 22px;
  }
  .heading {
    line-height: 40px;
    margin-bottom: 12px;
  }
  .videoMain video {
    height: 42vh;
    object-fit: cover;
  }
  .logo {
    margin-bottom: 12px;
  }
  .rightIcon {
    margin-left: 19px;
  }
  .datePickerIcon {
    right: 60px;
  }
  .contactUsContainer {
    width: 60% !important;
  }
  .followUsContainer {
    width: 40% !important;
  }
  .inputMainContainer .contactUsText,
  .inputContainer {
    width: 60%;
    margin-top: 2%;
    margin-inline-start: inherit;
  }
  .inputMainContainer .contactUsText {
    width: auto;
  }
  .videoLinkCard {
    padding: 8px;
    right: 5%;
    left: 5%;
    bottom: 30px;
  }
  .videoLinkCard h4 {
    font-size: 18px;
    margin-bottom: 0.25rem;
  }
  .LockSignIcon {
    width: 100%;
  }
  .videoLinkCard p {
    font-size: 15px;
    margin-bottom: 8px;
  }
  .logoContainer {
    justify-content: center;
    margin-bottom: 16px;
  }
  .cardHeading2 {
    text-align: center;
  }
  .inputMainContainer .contactUsText {
    margin: auto;
  }
  #securityCard {
    margin-top: 20px;
  }
  #footer .address {
    margin-bottom: 16px !important;
  }
  .followUsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 1.5rem !important;
    margin-bottom: 12px;
  }
  #footer {
    padding-block: 30px;
    margin-top: 40px;
  }
  .MyProfileCard {
    margin: 0 auto 20px auto;
    width: 90%;
  }
  .MyProfileCard:first-child {
    margin-top: 60px;
  }
  .ClassJoinSection {
    padding-left: 6px;
    width: 100%;
  }
  .roomBookingCard {
    padding: 16px 12px;
  }
  .Timing {
    max-width: 50%;
  }
  .subTxt {
    white-space: nowrap;
  }
  .timeContainer {
    bottom: -43px;
    left: 20%;
  }
  .buttonContainer {
    bottom: 70px;
  }
  .mainVideoContainer {
    width: 100%;
    height: auto;
  }
  .todayBookingContainer {
    width: 90%;
  }
  .modalInner {
    max-width: 90%;
    width: 90%;
  }
  .modal_inner {
    min-width: 90%;
  }
  .DatePicker {
    padding: 12px 10px;
    margin: -6px;
  }
  .cancelButton {
    white-space: nowrap;
  }
  .fullScreen {
    height: 92vh !important;
  }
  .fullScreen video {
    height: 92vh !important;
    object-fit: unset;
  }
}
@media (max-width: 992px) {
  .nav-link {
    margin-right: 0;
  }
}
@media (max-width: 320px) {
  .datePickerIcon {
    right: 72px;
    width: 18px;
    height: 18px;
  }
}
@media only screen and (min-width: 481px) and (max-width: 620px) {
  .modalInner {
    width: 100%;
  }
  .fullScreen {
    height: 92vh !important;
  }
  .fullScreen video {
    height: 92vh !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .fullScreen .localParticipantSwitch {
    overflow: hidden;
  }
  .roomBookingCard {
    width: 48% !important;
    margin-right: 16px;
  }
  .roomBookingCard:last-child {
    margin-right: 0;
  }
  .LockSignIcon {
    max-width: 100%;
  }
  .codeInput input {
    width: 58px !important;
    height: 58px !important;
  }
  .todayBookingContainer {
    width: 38%;
    margin-top: 0;
  }
  .todayBookingContainer .roomBookingCard {
    width: 100% !important;
  }
  .ClassJoinSection {
    width: 100%;
  }
  .myBooking {
    width: 58% !important;
  }
  .inputContainer,
  .inputMainContainer .contactUsText {
    width: 96%;
  }
  .inputContainer input,
  .inputContainer input::placeholder {
    font-size: 15px;
  }
  .securityCard {
    width: 65%;
  }
  .MyProfileCard {
    width: 72% !important;
    padding: 24px 14px 40px;
  }
  .bookButtonText {
    width: 70px;
    padding: 20px 6px;
    height: 100%;
  }
  .Arrows {
    width: 14px;
  }
  .cancelButton {
    padding: 5px;
    display: flex !important;
    align-items: center !important;
    font-size: 12px !important;
  }
  .bgImage {
    height: 380px;
  }
  .todayBookingContainer img {
    max-height: 270px;
  }
  .classSection {
    align-items: stretch;
  }
  .classJoinerContainer {
    height: auto;
  }
}
@media only screen and (min-width: 600px) and (max-width: 780px) {
  .videoOuter {
    margin: 2% 0px;
    flex-wrap: wrap;
  }
  .fullScreen video {
    height: 85vh !important;
  }
  .mainVideoContainer {
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 644px) {
  .contactUsContainer {
    width: 74% !important;
  }
  .FocusAbstract {
    margin-top: 2px;
  }
  .followUsContainer {
    width: 26% !important;
  }
  .inputMainContainer .contactUsText,
  .inputContainer {
    width: 60%;
    margin-top: 2%;
    margin-inline-start: inherit;
  }
  .inputMainContainer .contactUsText {
    width: auto;
  }
}
@media only screen and (min-width: 381px) and (max-width: 390px) {
  .contactUsContainer {
    width: 50% !important;
  }
  .videoMain video {
    height: 42vh;
  }
  .followUsContainer {
    width: 50% !important;
  }
  .inputMainContainer .contactUsText,
  .inputContainer {
    width: 70%;
    margin-top: 2%;
    margin-inline-start: inherit;
  }
  .inputMainContainer .contactUsText {
    width: auto;
  }
}
@media only screen and (min-width: 280px) and (max-width: 380px) {
  .main {
    align-items: center;
    display: grid;
    justify-content: center;
  }
  .CoworkersIcon,
  .FocusAbstract {
    display: none;
  }
  .securityCard {
    padding: 5% 40px;
    width: 335px;
    height: 490px !important;
    margin: auto;
  }
  .stopSignImg {
    width: 290px;
    margin: auto;
    height: 290px;
  }
  .logo {
    height: 35px;
    width: 25px;
  }
  .heading {
    font-size: 33px;
    line-height: 65px;
  }
  .options {
    width: 95px !important;
  }
  .optionsPlaceholder {
    width: 95px;
    font-size: 15px;
  }
  .heading2 {
    font-size: 13px;
    line-height: 25px;
  }
  .MyProfileCard {
    width: auto;
  }
  .button {
    margin-top: 17px;
    padding: 8px 18px;
    font-size: 14px;
    line-height: 29px;
  }
  .linksDiv {
    display: contents;
  }
  .link {
    font-size: 24px;
  }
  .LinkImages {
    width: 200px;
  }
  .sideImages {
    margin-top: 22px;
    text-align: center;
  }
  .middleImg {
    margin-top: 5px;
    text-align: center;
  }
  .LockSignIcon {
    width: 220px;
    height: 137px;
  }
  .cardHeading {
    font-size: 28px;
  }
  .BookingCards_Outer {
    display: contents;
  }
  .Timing {
    margin: 0px 20px 0px 4px !important;
    font-size: 12px;
  }
  .bookButton {
    font-size: 16px;
    width: 87px;
    padding: 0px;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }
  .cancelButton {
    font-size: 16px;
    width: 87px;
    padding: 0px;
  }
  .contactUsText {
    font-size: 20px;
  }
  #footer .address {
    font-size: 14px;
  }
  #footer img {
    width: 20px;
    height: 20px;
  }
  #footer input,
  #footer input::placeholder {
    font-size: 14px !important;
  }
  #footer button {
    font-size: 14px !important;
  }
  .contactUsContainer {
    width: 50% !important;
  }
  .followUsContainer {
    width: 50% !important;
  }
  .inputMainContainer .contactUsText,
  .inputContainer {
    width: 70%;
    margin-top: 2%;
    margin-inline-start: inherit;
  }
  .inputMainContainer .contactUsText {
    width: auto;
  }
  .videoMain video {
    height: 42vh;
    width: 220px;
  }
}
@media (max-width: 480px) {
  .video-container {
    flex-basis: 50% !important;
  }
  .videoMain .video {
    height: 50vh;
  }
  .contactUsText {
    font-size: 16px;
  }
  #footer .address {
    font-size: 12px;
  }
  #footer img {
    width: 20px;
    height: 20px;
  }
  #footer input,
  #footer input::placeholder {
    font-size: 12px !important;
  }
  #footer button {
    font-size: 12px !important;
  }
  .contactUsContainer {
    width: 50% !important;
  }
  .followUsContainer {
    width: 50% !important;
  }
  .inputMainContainer .contactUsText,
  .inputContainer {
    width: 70%;
    margin-top: 2%;
    margin-inline-start: inherit;
  }
  .inputMainContainer .contactUsText {
    width: auto;
  }
}
@media (max-width: 991px) {
  .nav-link:hover {
    border-bottom: 0px solid #fff;
  }
}
@media only screen and (min-width: 350px) and (max-width: 360px) {
  .videoMain video {
    height: 42vh !important;
  }
}
@media (max-width: 280px) {
  .ClassJoinSection,
  .fixedHeader {
    width: 295px;
  }
  .DatePicker {
    margin: -7px;
  }
}
@media only screen and (min-width: 820px) and (max-width: 820px) {
  #footer {
    margin-top: 218px !important;
  }
  .cancelButton {
    display: flex !important;
    align-items: center !important;
    font-size: 12px !important;
  }
  .Timing {
    font-size: 10px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 768px) {
  #footer {
    margin-top: -61px !important;
  }
  .cancelButton {
    display: flex !important;
    align-items: center !important;
    font-size: 12px !important;
  }
  .Timing {
    font-size: 10px !important;
  }
}
@media only screen and (min-width: 912px) and (max-width: 912px) {
  #footer {
    margin-top: 410px !important;
  }
  .cancelButton {
    display: flex !important;
    align-items: center !important;
    font-size: 12px !important;
  }
  .Timing {
    font-size: 10px !important;
  }
}
