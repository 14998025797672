body {
  background: #fafaff !important;
}
body {
  text-size-adjust: none !important;
  -webkit-text-size-adjust: none !important;
  -ms-text-size-adjust: none !important;
  -moz-text-size-adjust: none !important;
}
.bgImage {
  background-image: url("../images/BGRectangle.png");
  height: 415px;
  background-size: cover;
  background-repeat: no-repeat;
}
.pageContent {
  width: 100%;
  justify-content: center;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bglight {
  padding: 20px 40px 0px 30px !important;
}
.GoogleLogin_btn {
  width: 240px !important;
  border-radius: 20px !important;
  margin: auto !important;
  margin-top: 40px !important;
}
.logo {
  height: 40px;
  width: 30px;
}
.nav-link:hover {
  border-bottom: 1px solid;
}
.navbar a.active {
  border-bottom: 1px solid;
}
a {
  color: white !important;
}
.justify-content {
  justify-content: flex-end !important;
}
.heading {
  font-family: "Baloo Bhai";
  font-size: 3.8rem;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0;
}
.heading2 {
  font-family: "Baloo Bhai";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 1e-5em;
  color: #ffffff;
}
.sideImages p,
.middleImg p {
  color: #070707 !important;
  font-family: "Baloo Bhai";
  font-weight: 400;
  font-size: 34px;
  text-align: center;
}
.fullScreen {
  height: 90vh !important;
  width: 100vw;
}
.fullScreen video {
  height: 90vh !important;
  border-radius: 0px !important;
}
.sideImages {
  margin-top: -7%;
  text-align: center;
}
.middleImg {
  text-align: center;
  margin-top: 30px;
}
.linksDiv {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0px 50px;
  margin-top: 32px;
}
.link:hover {
  color: blue !important;
  cursor: pointer;
}
.LinkImages {
  margin: auto;
}
.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  margin-top: 18px;
  background-color: #ff9600;
  padding: 10px 22px;
  border-radius: 8px;
  font-family: "Baloo Bhai";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  letter-spacing: 1e-5em;
  color: #ffffff;
  margin-bottom: 12px;
}
.button {
  margin-top: 18px;
  background-color: #ff9600;
  border: 1px solid #ff9600;
  padding: 10px 22px;
  border-radius: 8px;
  font-family: "Baloo Bhai";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  letter-spacing: 1e-5em;
  color: #ffffff;
  margin-bottom: 12px;
  transition: 0.7s background;
}
.button:hover,
.submit:hover {
  background: transparent;
  color: #ff9600;
}
.getStarted {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 41px;
  position: relative;
}
.securityText {
  font-size: 18px;
  text-align: center;
  color: #12193f;
  margin-bottom: 18px;
  font-weight: 500;
  text-align: left;
  line-height: 1.2;
}
.DropdownItem {
  color: #000000 !important;
  font-family: "Baloo Bhai";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
.securityCard {
  align-items: center !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  padding: 5.5%;
  gap: 24px;
  position: absolute;
  width: 677px;
  height: 540px !important;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12),
    0px 10px 32px -4px rgba(24, 39, 75, 0.1);
  border-radius: 16px !important;
  margin: 50px;
}
.MyProfileCard {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  padding: 24px;
  position: absolute;
  width: 665px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12),
    0px 10px 32px -4px rgba(24, 39, 75, 0.1);
  border-radius: 16px !important;
  margin: 60px 32px 20px 0;
  border: 0 !important;
}
.questionsTxt {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 1e-5em;
  color: #12193f;
  margin-bottom: 8px !important;
}
#footer {
  padding-block: 50px;
  background: #f5f5fa;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 20px;
}
#footer .address {
  color: #000;
  font-size: 18px;
  margin: auto;
  font-family: "Rubik";
  font-weight: 400;
}
.socialIconContainer {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.7s background;
  border: 1px solid #fff;
  margin-right: 16px;
}
.socialIconContainer:last-child {
  margin-right: 0;
}
.followUsContainer {
  padding-left: 0px !important;
}
.followUsContainer a {
  margin-right: 8px;
}
.joinFriendRoom .HelpText {
  text-align: center;
  width: 100%;
  margin-top: 10px;
  transition: 0.7s;
}
.HelpText {
  transition: 0.7s;
}
.joinFriendRoom .HelpText:hover,
.HelpText:hover {
  color: #000;
}
.socialIconContainer:hover {
  background: transparent;
}
.contactUsText {
  color: #000;
  font-family: "Baloo Bhai";
  font-size: 28px;
  margin-bottom: 8px !important;
}
.socialIconContainer i {
  font-size: 20px;
  color: #000;
}
.socialIconContainer:hover i {
  color: #fff;
}
.socialIconContainer a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.roomText {
  margin-bottom: 18px !important;
}
.inputMainContainer .contactUsText {
  width: 80%;
  margin-inline-start: auto;
}
.inputContainer {
  background: #fff;
  border-radius: 80px;
  min-height: 48px;
  padding: 4px 4px 4px 10px;
  width: 80%;
  display: flex;
  margin-inline-start: auto;
}
.inputContainer input {
  width: 60%;
  border: 0;
  background: transparent;
  padding-left: 5px;
  color: #000;
  font-family: "Baloo Bhai";
  font-size: 18px;
}
.inputContainer input::placeholder {
  padding-left: 5px;
  color: #000;
  font-family: "Baloo Bhai";
  font-size: 18px;
}
.inputContainer input:focus-visible {
  outline: 0 !important;
}
.submit {
  background: #ff9600;
  border: 1px solid #ff9600;
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40%;
  font-family: "Baloo Bhai";
  font-size: 18px;
  transition: 0.7s background;
}
.submit p {
  margin-top: 4px;
}
.footerLogo {
  filter: invert(1);
  margin-bottom: 32px;
}
.Questions {
  margin-bottom: 34px;
}
.questionList {
  list-style-type: decimal;
}
.RemainingTime {
  margin-left: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 1e-5em;
  color: #aaaaaa;
}
.TimingsOuter {
  display: flex;
}
.TimingsOuter p {
  margin-right: 2px !important;
}
.options {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 178px;
  border: 2px solid #d6d6d6;
  border-radius: 8px;
  flex: none;
  cursor: pointer;
  order: 0;
  flex-grow: 1;
  margin: 0px 10px 5px 0px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #72797c;
  cursor: pointer;
}
.optionsPlaceholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 1e-5em;
  color: #72797c;
  flex: none;
  cursor: pointer;
  order: 0;
  flex-grow: 0;
  width: 141px;
  text-align: center;
}
.options:hover {
  background-color: #e5e4e2;
}
.nav-link {
  font-family: "Baloo Bhai";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  letter-spacing: 1e-5em;
  color: #ffffff;
  margin-right: 28px;
  border-bottom: 1px solid transparent;
  padding-bottom: 0 !important;
  transition: 0.7s;
}
.nav-item:last-child a {
  margin-right: 0;
}
.navbar-light .navbar-toggler {
  color: white !important;
  border-color: white !important;
}
.navbar-toggler-icon {
  background-image: url("../images/navbarToggler.png") !important;
}
.navbar-nav li {
  margin-right: 24px;
}
.optionsOuter {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 16px;
}
.button p {
  margin-top: 5px;
}
.cardHeading2 {
  font-family: "Baloo Bhai";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 1e-5em;
  color: #12193f;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.bookingCard {
  padding-top: 0 !important;
}
.fixedHeader {
  position: sticky;
  background: #fff;
  top: 0;
  width: 100%;
  padding-top: 24px;
}
.classJoinContainer {
  width: 100%;
}
.cardHeading {
  font-family: "Baloo Bhai";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 1e-5em;
  color: #12193f;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 18px !important;
}
.roomBookingCard {
  height: 613px;
}
.main {
  display: flex;
  justify-content: center;
  align-items: center;
}
.stopSignImg {
  width: 350px;
  height: 335px;
  align-self: flex-end !important;
  margin-left: 20px;
}
.alignSelf {
  align-self: center !important;
}
.navOuter {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
  padding: 15px 50px 0px 30px;
}
.mr-0 {
  margin-right: 0px !important;
}
.LockSignIcon {
  align-self: center !important;
  margin-bottom: 20px;
}
.DatePicker {
  border-radius: 10px;
  border: 2px solid #ff9600;
  padding: 16px 30px 16px 12px;
  margin: 8px;
  cursor: pointer;
}
.expiredTime_msg {
  font-family: "Baloo Bhai";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 1e-5em;
  color: #12193f;
  margin-top: 13%;
}
.DatePickerOuter {
  display: flex;
  align-items: center;
  margin: 25px 0px;
}
.classroom_outer {
  width: 100%;
}
:focus-visible {
  outline: 1px solid #ff9600;
  border-radius: 10px;
}
#preloaderload {
  position: fixed;
  width: 81%;
  height: 100%;
  top: 0;
  left: 20%;
}
.preload-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  z-index: 999;
}
input {
  margin: 0;
  font-family: "Rubik" !important;
  font-style: Bold !important;
  font-size: 18px !important;
  color: #ff9600;
  line-height: 21px !important;
}
.DatePickerImg-outer {
  cursor: pointer;
  justify-content: end;
  display: flex;
  align-items: center;
  width: 266px;
  position: absolute;
}
.datePickerIcon {
  position: absolute;
  height: 25px;
  width: 25px;
  pointer-events: none;
  right: 22px;
}
.okButton {
  min-width: 120px;
  transition: 0.7s background;
  border: 1px solid #ff9600;
}
.okButton:hover {
  background: transparent;
  color: #ff9600;
}
#basic-navbar-nav {
  text-align: center;
}
.Arrows {
  width: 18px;
  cursor: pointer;
}
.ClassJoinSection {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 12px;
  margin-bottom: 14px;
  width: 598px;
  min-height: 60px;
  background: #ffffff;
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  align-self: center;
}
.MyBookings {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 0px 16px;
  margin-bottom: 19px;
  width: 598px;
  height: 69px;
  background: #ffffff;
  border: 0.5px solid #aaaaaa;
  border-radius: 8px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  align-self: center;
}
.subjectTxt {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 1e-5em;
  color: #12193f;
  margin-bottom: 18px !important;
}
.roomBookingCard {
  padding: 24px 28px 40px 28px;
}
.Timing {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 1e-5em;
  color: #000000;
}
.BookRoom_txt p {
  color: #ff9600;
  font-family: "Baloo Bhai";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 1e-5em;
}
.msgCard {
  padding-top: 4% !important;
}
.outer_Container {
  width: 805px;
  height: 626px;
  display: contents;
}
.CoworkersIcon {
  position: absolute;
  width: 132px;
  height: 133px;
  left: 997px;
  top: 606px;
  z-index: 999;
}
.FocusAbstract {
  position: absolute;
  width: 156px;
  height: 162px;
  left: 880px;
  top: 478px;
}
.BookRoom_txt {
  margin-top: 20px;
  text-align: center;
  width: 100%;
}
.Msg {
  text-align: center;
  line-height: 40px;
}
.bookingTime {
  margin: 0px 15px 0px 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 1e-5em;
  color: #000000;
}
.BookingCards_Outer {
  display: flex;
  margin-bottom: 50px;
}
.bookButton {
  align-items: center;
  display: flex;
  align-items: center;
  padding: 20px 32px;
  gap: 10px;
  width: 107px;
  justify-content: center;
  height: 69px;
  background: #ff9600;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  font-family: "Baloo Bhai";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  letter-spacing: 1e-5em;
  color: #ffffff;
}
.subTxt {
  margin: 0px 14px !important;
  color: #aaaaaa;
  text-transform: capitalize;
}
.HelpText {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 1e-5em;
  color: #72797c;
  margin-top: 8px;
  cursor: pointer;
}
.cancelButton {
  align-items: center;
  gap: 10px;
  font-family: "Baloo Bhai";
  font-style: normal;
  background: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  letter-spacing: 1e-5em;
  color: #ff5e59;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-right: 10px;
}
.link {
  text-decoration: none;
}
button {
  border: none;
}
p {
  margin-bottom: 0px !important;
}
.joinClassroom_Card {
  max-height: 613px !important;
  min-height: 270px !important;
}
.todayBooking::-webkit-scrollbar {
  display: none;
}
.todayBooking {
  width: 100% !important;
  max-height: 250px !important;
}
.scrollBar {
  overflow: scroll;
}
.scrollBar::-webkit-scrollbar {
  display: none;
}
.todayBookingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
}
@media (max-width: 620px) {
  .msgCard {
    width: 90% !important;
  }
  .CoworkersIcon {
    left: 54px;
    top: 605px;
    position: absolute;
  }
  .FocusAbstract {
    left: 228px;
    top: 589px;
    position: fixed;
  }
  .DatePicker {
    width: 100%;
  }
  .Arrows {
    margin-left: 2px;
  }
  .joinFriendRoom input {
    width: 48px !important;
    height: 48px !important;
  }
}
.nav-link:hover {
  border-bottom: 1px solid #fff;
}
#securityCard {
  height: auto !important;
}
.buttonContainer {
  position: fixed;
  bottom: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  z-index: 999;
}
.videoContainer {
  display: flex;
}
.muteIcon {
  position: absolute;
  top: 13px;
  right: 13px !important;
}
.fullScreen video {
  object-fit: contain;
}
.audioContainer {
  position: relative;
}
.videoMain {
  position: relative;
  height: 42vh;
  border-radius: 8px;
}
.videoMain video {
  border-radius: 8px;
  object-fit: unset;
  height: 42vh;
}
.videoMain video {
  height: 100%;
}
.userName {
  position: absolute;
  bottom: 14px;
  left: 20px;
  color: #fff;
  z-index: 99;
}
.mainVideoContainer {
  flex-wrap: wrap;
  column-gap: 6px;
  row-gap: 4px;
  padding: 20px 0px 100px 0;
  width: 80%;
  margin-inline: auto;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.videoOuter {
  display: flex;
  flex: 1 1 50%;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.video-container {
  max-width: 50%;
  flex-basis: 50%;
  position: relative;
  width: 100%;
  padding: 10px;
}
.videoNotAvailable {
  background: #1d1c1c;
  height: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42vh;
}
#videoBg {
  background: rgb(32, 33, 36);
  height: auto;
  min-height: 100vh;
}
.timeContainer {
  position: absolute;
  bottom: 20px;
  left: 10px;
  display: flex;
}
.timeContainer p {
  color: #fff;
  display: flex;
  position: relative;
  margin-inline-end: 40px;
}
.localParticipantVideo video {
  transform: scale(-1, 1);
}
.timeContainer p::after {
  content: "";
  position: absolute;
  right: -20px;
  width: 1px;
  height: 100%;
  background: #fff;
}
.timeContainer p:last-child::after {
  background: transparent;
}
.timeContainer p:last-child {
  margin-inline-end: 0;
}
.timeContainer img {
  width: 20px;
  margin-inline-end: 10px;
}
.preload-content2 .sl-spinner2 {
  max-height: 60px;
}
.videoLinkCard {
  position: absolute;
  bottom: 20px;
  right: 5%;
  background: #fff;
  max-width: 360px;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
}
.videoLinkCard h4 {
  font-weight: 600;
  color: rgb(32, 33, 36);
}
.videoLinkCard p {
  color: rgb(60, 64, 67);
  font-size: 17px;
  margin-bottom: 16px !important;
}
.inviteCodeBox {
  background: rgb(241, 243, 244);
  min-height: 48px;
  padding-inline: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.videoLinkCard .inviteCodeBox p {
  margin-bottom: 0 !important;
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.copyIcon {
  font-weight: 600;
  cursor: pointer;
}
.enteringRoomCard {
  margin-top: -29% !important;
  padding-bottom: 30px;
}
.crossIcon {
  font-size: 23px;
  width: 100%;
  display: flex;
  justify-content: end;
  cursor: pointer;
}
.inRoom {
  color: #f57e20;
}
.inProgress {
  color: #f6b918;
}
.completed {
  color: #20b24b;
}
.cancel {
  text-decoration: underline;
}
.booked {
  color: #005190;
}
.nav-link {
  color: rgba(255, 255, 255, 0.7) !important;
}
.activeNav {
  color: #fff !important;
}
.cancelled {
  color: #00ffff;
}
.hereText {
  font-weight: 400;
  font-size: 20px;
  color: #12193f;
  font-family: "Rubik";
  margin-bottom: 16px !important;
}
.myBooking {
  width: 58%;
}
.todayBookingContainer {
  width: 38%;
}
.roomBookingCard {
  width: 56%;
}
.ClassJoinSection {
  width: 100%;
}
.modalOverlay {
  width: 100%;
  min-height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5.5px);
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalInner {
  background-color: #fff;
  max-width: 60%;
  width: 30%;
  padding: 20px 30px;
  position: relative;
  border-radius: 12px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 400px;
  animation: modalAnimation 0.7s;
}
.modal_inner {
  min-width: 600px;
}
@keyframes modalAnimation {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
.modalInner h3 {
  font-size: 24px;
  font-family: "Baloo Bhai";
  color: #000;
  margin: 42px 0 16px 0;
  text-transform: capitalize;
  text-align: center;
}
.modalInner .crossIconContainer {
  width: 42px;
  height: 42px;
  background: #fff;
  box-shadow: 0px 0px 4px rgba(24, 39, 75, 0.12);
  border-radius: 50%;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 18px;
  border: 1px solid #fff;
  transition: 0.7s border;
}
.modalInner .crossIconContainer:hover {
  box-shadow: 0px 0px 0px transparent;
}
.modalInner .crossIconContainer img {
  width: 18px;
  height: 18px;
  transition: transform 0.7s ease-in-out;
}
.modalInner .crossIconContainer:hover img {
  transform: rotate(180deg);
}
.modalInner .list {
  list-style: decimal;
  padding-inline: 20px;
  margin-bottom: 0;
}
.modalInner .list li {
  font-size: 18px;
  font-family: "Rubik";
  font-weight: 500;
  color: #000;
  margin-bottom: 8px;
}
#buttonContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.todayBookingContainer {
  width: 38%;
}
.todayBookingContainer img {
  margin-top: 20px;
  max-height: 320px;
  aspect-ratio: 4/4;
}
.classSectionInner {
  width: 75%;
}
