.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.box-1 {
  position: absolute;
  bottom: -48%;
  left: 20px;
}

.box-2 {
  position: absolute;
  bottom: -70%;
  left: 47%;
  transform: translateX(-50%);
}

.box-3 {
  position: absolute;
  bottom: -48%;
  right: 20px;
}

/* .boxMain {
  position: absolute;
  left: 0;
  bottom: -70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box-2 {
  margin-top: 15%;
} */
/* @media (max-width: 900px) {
  .box-1,
  .box-2,
  .box-3 {
    width: 30%;
  }
  .box-1 img,
  .box-2 img,
  .box-3 img {
    height: auto;
  }
}
@media (max-width: 560px) {
  .boxMain {
    bottom: -40%;
  }
} */
.video {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.video video {
  width: 90%;
  border-radius: 12px;
}

.room {
  width: 100%;
}

.participants {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}

.participant {
  width: 50%;
  margin-bottom: 8px;
}

.avControl {
  display: flex;
}

.iconContainer {
  width: 48px;
  height: 48px;
  background: rgb(60, 64, 67);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 8px;
}

.iconContainer svg {
  color: #fff;
}

.iconContainer .inviteCodeIcon {
  width: 30px;
  height: 30px;
  filter: invert(1);
  margin-top: 2px;
  margin-left: 2px;
}

.leaveCall {
  background: rgb(234, 67, 53);
}

.video .avControl {
  position: absolute;
  left: 45%;
  bottom: -20%;
}

.audio .avControl {
  position: absolute;
  left: 40%;
  bottom: -20%;
}

.time-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 60px;
  font-weight: 700;
  color: #12193f;
  animation: fadeIn 1s infinite;
  bottom: 80px;
  position: absolute;
}

.timeStatus {
  font-size: 36px;
}

@keyframes fadeIn {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.enteringRoomMain {
  background: rgba(0, 0, 0, 0.87) !important;
}

.buttonContainer {
  position: relative;
}

@media screen and (prefers-color-scheme: dark) {
  p {
    color: #fff;
  }
}